<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="dark"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Nuovo appuntamento</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <form @submit.prevent="creaAppuntamento()">
          <ion-list class="fields">
            <!-- Cliente -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Cliente
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-input type="text" v-model="appuntamento.titolo" placeholder="Cliente"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Titolo -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Titolo
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-input type="text" v-model="appuntamento.titolo" placeholder="Titolo evento"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Data -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Data inizio
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-datetime
                    name="date_start"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2022"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="appuntamento.data_inizio"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Data fine-->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Data fine
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-datetime
                    name="date_end"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2022"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="appuntamento.data_fine"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Ora inizio -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">Ora inizio</div>
                <div class="value">
                  <ion-datetime
                    name="start_time"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="appuntamento.ora_inizio"
                    placeholder="Seleziona ora di inizio"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Ora fine -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">Ora fine</div>
                <div class="value">
                  <ion-datetime
                    name="end_time"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="appuntamento.ora_fine"
                    placeholder="Seleziona ora di fine"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Descrizione -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">Descrizione</div>
                <div class="value">
                  <ion-textarea type="text" rows="3" v-model="appuntamento.descrizione" placeholder="Inserisci la descrizione"> </ion-textarea>
                </div>
              </div>
            </ion-item>

            <div class="actions">
              <ion-button size="default" type="submit" expand="block" class="btn_richiesta">
                Crea appuntamento
              </ion-button>
            </div>
          </ion-list>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
  IonList,
  IonItem,
  IonDatetime,
  IonTextarea,
  IonText,
  IonInput,
  IonIcon,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref, reactive, onMounted } from "vue";

import moment from "moment";

import { openToast } from "../services/toast";

import apiAppuntamenti from "../services/appuntamenti";
import apiClienti from "../services/clienti";

export default defineComponent({
  name: "ModalNewAppuntamento",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonIcon,
  },
  setup(props, context) {
    const customers = ref([]);
    //console.log(props);

    const start_date = new Date();
    const end_date = start_date;
    const end_hour = new Date();
    const start_hour = new Date(end_hour);
    start_hour.setHours(start_hour.getHours() - 1);
    end_hour.setHours(start_hour.getHours() + 2);

    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id; //set userID in user field

    const appuntamento = reactive({
      user: "", //from logged user
      titolo: "",
      data_inizio: start_date.toISOString(),
      data_fine: end_date.toISOString(),
      ora_inizio: start_hour.toISOString(),
      ora_fine: end_hour.toISOString(),
      descrizione: "",
    });

    const successResponse = ref(false);

    const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

    /**
     * Get all clienti
     */
    async function loadClienti() {
      try {
        customers.value = await apiClienti.getClienti();
        console.log(customers.value);
      } catch (error) {
        customers.value = [];
        openToast("Errore durante la richiesta dei clienti", "toast_danger");
      }
    }

    /**
     * Close modal after created new appuntamento
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        //console.log(res);
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     * Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     * Create new appuntamento for the selected cliente
     */
    function creaAppuntamento() {
      const data_inizio_formatted = moment(appuntamento.data_inizio).format("YYYY-MM-DD");
      const data_fine_formatted = moment(appuntamento.data_inizio).format("YYYY-MM-DD");
      const ora_inizio_formatted = appuntamento.ora_inizio != "" ? moment(appuntamento.ora_inizio).format("HH:mm") : "";
      const ora_fine_formatted = appuntamento.ora_fine != "" ? moment(appuntamento.ora_fine).format("HH:mm") : "";

      appuntamento.user = userID; //set user as logged user id

      /* Controlli su data e orari */
      const ora_inizio = moment(appuntamento.ora_inizio).valueOf();
      const ora_fine = moment(appuntamento.ora_fine).valueOf();
      const dataInizio = moment(appuntamento.data_inizio).valueOf();
      const dataFine = moment(appuntamento.data_fine).valueOf();

      if (!appuntamento.titolo) {
        openToast("Il titolo è obbligatorio", "toast_danger");
        return;
      }

      if (dataInizio > dataFine) {
        openToast("La data di inizio deve essere maggiore della data di fine", "toast_danger");
        return;
      }

      if (moment(appuntamento.data_inizio).format("DD/MM/YYYY") < moment().format("DD/MM/YYYY")) {
        openToast("Non puoi inserire un'appuntamento per una data precedente ad oggi", "toast_danger");
        return;
      } else if (ora_inizio > ora_fine) {
        openToast("L'ora inizio non può essere maggiore dell'ora fine", "toast_danger");
        return;
      } else if (ora_inizio <= ora_fine && dataInizio <= moment().valueOf()) {
        fieldFlag.value = true;
      }

      //console.log("fieldFlag = ", fieldFlag.value);
      if (fieldFlag.value) {
        const data = new FormData();
        data.append("events_user", userID); //logged user
        //data.append("events_customer", props.id); //customer id as prop
        data.append("events_title", appuntamento.titolo);
        data.append("events_date_start", data_inizio_formatted + " " + ora_inizio_formatted);
        data.append("events_date_end", data_fine_formatted + " " + ora_fine_formatted);
        data.append("events_description", appuntamento.descrizione);

        // Display formData values
        for (const value of data.entries()) {
          console.log(value[0] + ", " + value[1]);
        }

        apiAppuntamenti
          .saveAppuntamento(data)
          .then((response) => {
            if (response.data.status == 8) {
              openToast(response.data.message, "toast_danger");
            } else {
              const res = response.data.data[0];
              successResponse.value = true;
              closeModalOnSubmit(successResponse, res);
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            openToast("Errore durante la creazione dell'appuntamento", "toast_danger");
          })
          .finally(() => {
            //console.log("fine creazione appuntamento");
          });
      }
    }

    onMounted(() => {
      loadClienti();
    });

    return {
      appuntamento,
      creaAppuntamento,
      closeModal,
      userID,
      arrowBackOutline,
      customers,
    };
  },
});
</script>

<style scoped>
.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.example {
  border: 1px solid black;
}

.alert-wrapper {
  width: 90% !important;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative;
  min-height: 100%;
  background: #f8fafc;
  padding: 16px;
}

.fields {
  width: 100%;
  border-radius: 4px;
  /*padding: 10px;*/
  padding: 16px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
}

.field_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #6b7280;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  margin-bottom: 4px;
  --ripple-color: transparent;
}
ion-datetime,
ion-select {
  max-width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  --padding-start: 8px;
}

.picker-opt.picker-opt-selected,
.picker-prefix,
.picker-suffix {
  color: red;
}

.picker-button.sc-ion-picker-md,
.picker-button.activated.sc-ion-picker-md {
  color: red;
}

ion-input,
ion-textarea {
  --placeholder-color: #6b7280;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-top: 0;
}

.btn_foto {
  --background: #0284c7;
  --background-activated: #0369a1;
  --background-hover: #0369a1;
  --background-focused: #0369a1;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 16px;
}
.btn_richiesta {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-left: 0;
  --padding-right: 0;
  --inner-padding-end: 0;
}
</style>
