<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="dark">
                    <div>Appuntamenti</div>
                </ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="getEvents()">
                        <ion-icon slot="start" :icon="refresh" color="dark"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div v-if="loading" class="loading_spinner">
                <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>
            <div class="page_content" v-else>
                <!--         <div class="nuova_evento_container">
          <div class="evento_button" @click="newAppuntamento">
            <span>Crea appuntamento</span>
          </div>
        </div> -->

                <div v-if="!events.length" class="ion-text-center ion-padding loading_spinner">
                    <div class="no_events">Nessun appuntamento da visualizzare</div>
                </div>

                <div v-else class="container_eventi">
                    <Calendar v-model="date" :value="date" is-expanded :attributes="setAttributes" @dayclick="dayClicked" ref="refCalendar"> </Calendar>

                    <!-- Today events -->
                    <div v-if="showTodayEvents">
                        <div class="selected_date">
                            {{
                                date.toLocaleDateString("it-IT", {
                                    weekday: "long",
                                    day: "numeric",
                                })
                            }}
                        </div>
                        <div class="selected_day">
                            <div v-if="todayEvents.length != 0">
                                <div v-for="event in todayEvents" :key="event.events_id" class="single_event" @click="openDetailModal(event)">
                                    <div class="title">
                                        {{ riferimentoCliente(event) }}
                                    </div>
                                    <div class="orari">
                                        {{ getHours(event.events_date_start) }} -
                                        {{ getHours(event.events_date_end) }}
                                    </div>
                                    <div class="orari event_description" v-if="event.events_description">
                                        {{ parseHtmlEnteties(event.events_description) }}
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="no_selected_event">
                                    Nessun appuntamento per oggi
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Day clicked events -->
                    <!--           <div class="selected_date" v-if="selectedDay.date">
            {{
              selectedDay.date.toLocaleDateString("it-IT", {
                weekday: "long",
                day: "numeric",
              })
            }}
          </div>
          <div v-if="selectedDay.attributes" class="selected_day">
            <div v-if="selectedDay.attributes.length != 0">
              <div v-for="{ customData } in selectedDay.attributes" :key="customData.events_id" @click="openDetailModal(customData)" class="single_event">
                <div class="title">
                  {{ riferimentoCliente(customData) }}
                </div>
                <div class="orari">
                  {{ getHours(customData.events_date_start) }} -
                  {{ getHours(customData.events_date_end) }}
                </div>
                <div class="orari event_description" v-if="customData.events_description">
                  {{ parseHtmlEnteties(customData.events_description) }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="no_selected_event">Nessun appuntamento</div>
            </div>
          </div> -->

                    <div class="selected_date" v-if="dataDaVisualizzare">
                        {{
                            dataDaVisualizzare.toLocaleDateString("it-IT", {
                                weekday: "long",
                                day: "numeric",
                            })
                        }}
                    </div>
                    <div v-if="dataDaVisualizzare">
                        <div v-if="giornoCliccato.length != 0" class="selected_day">
                            <div v-for="event in giornoCliccato" :key="event.events_id" @click="openDetailModal(event)" class="single_event">
                                <div class="title">
                                    {{ riferimentoCliente(event) }}
                                </div>
                                <div class="orari">
                                    {{ getHours(event.events_date_start) }} -
                                    {{ getHours(event.events_date_end) }}
                                </div>
                                <div class="orari event_description" v-if="event.events_description">
                                    {{ parseHtmlEnteties(event.events_description) }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="selectedDay">
                            <div class="no_selected_event">Nessun appuntamento</div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    modalController,
    IonButtons,
    onIonViewWillEnter,
} from "@ionic/vue";
import { add, refresh, clipboard, calendar } from "ionicons/icons";

import { defineComponent, computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { Calendar } from "v-calendar";

import moment from "moment";

import { openToast } from "../services/toast";

import apiEventi from "../services/appuntamenti";

import AppuntamentoDetail from "./AppuntamentoDetail.vue";

import ModalNewAppuntamento from "../components/ModalNewAppuntamento.vue";

export default defineComponent({
    name: "Tab1",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

        const events = ref([]);
        const date = ref(new Date());
        const selectedDay = ref(new Date()); // Add state to store selected day
        const refCalendar = ref(null);

        const todayEvents = ref([]);
        const showTodayEvents = ref(true); //default mostra eventi di oggi
        const today = moment().format("YYYY-MM-DD");

        const giornoCliccato = ref([]);
        const dataDaVisualizzare = ref(null);

        /**
         * Get events from the server
         */
        async function getEvents() {
            loading.value = true;
            try {
                events.value = await apiEventi.getAppuntamenti(userID);
                //set todayEvents
                todayEvents.value = events.value.filter((event) => moment(event.events_date_start).format("YYYY-MM-DD") === today);
                //console.log(todayEvents.value);
            } catch (error) {
                showTodayEvents.value = true;
                //console.error(error);
                openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * Convert events array into attributes for calendar visualization
         * Assign key derived from original event.events_id field
         * Assign event element to attribute's customData property to access original event in template
         * Assign events_data_start and events_date_end as date to use in calendar visualization
         */
        const setAttributes = computed(() => {
            return events.value.map((event) => ({
                key: `event.${event.events_id}`,
                highlight: {
                    start: { color: "orange", fillMode: "solid" },
                    base: { color: "orange", fillMode: "light" },
                    end: { color: "orange", fillMode: "solid" },
                },
                dates: {
                    start: moment(event.events_date_start).format("YYYY/MM/DD"),
                    end: moment(event.events_date_end).format("YYYY/MM/DD"),
                },
                customData: event,
            }));
        });

        /**
         * Set today date for visualization on calendar click
         */
        const dayClicked = (day) => {
            showTodayEvents.value = false; //hide today event load on first time when clicked on Eventi tab
            selectedDay.value = day;

            if (selectedDay.value.attributes.length != 0) {
                giornoCliccato.value = [];
                selectedDay.value.attributes.forEach((event) => {
                    //giornoCliccato.value.push(event.customData);
                    //console.log(event.customData);
                    giornoCliccato.value.push(event.customData);
                });
            } else {
                giornoCliccato.value = [];
            }
            dataDaVisualizzare.value = selectedDay.value.date;

            //console.log(giornoCliccato.value);
        };

        /**
         * Passing id to retrive detail in AppuntamentoDetail page
         */
        async function openDetailModal(appuntamento) {
            //console.log(richiestaDetail);
            const modal = await modalController.create({
                component: AppuntamentoDetail,
                componentProps: {
                    data: appuntamento,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    const event_id = detail.data;
                    //devo filtrare sia todayEvents che events rimuovendo l'evento appena cancellato
                    events.value = events.value.filter((event) => event.events_id != event_id);
                    todayEvents.value = todayEvents.value.filter((event) => event.events_id != event_id);
                    giornoCliccato.value = giornoCliccato.value.filter((event) => event.events_id != event_id);
                    //openToast("Nuova richiesta inserita", "toast_success");
                }
            });
            return modal.present();
        }

        /*
         * Show correct user info
         */
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && cliente.customers_last_name) {
                return `${cliente.customers_name} ${cliente.customers_last_name}`;
            }
            return "";
        };

        /**
         * Return '-' if a customer field is null
         */
        function checkField(field) {
            if (field == null) {
                return " ";
            } else {
                return " - " + field;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to HH:mm
         */
        function getHours(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("HH:mm");
            } else {
                return "-";
            }
        }

        /**
         * Remove html tag from events description
         */
        function parseHtmlEnteties(str) {
            if (str) {
                const strippedString = str.replace(/(<([^>]+)>)/gi, "");
                return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
                    const num = parseInt(numStr, 10); // read num as normal number
                    return String.fromCharCode(num);
                });
            }
        }

        /**
         ** Open modal to create new appuntamento
         */
        async function newAppuntamento() {
            const modal = await modalController.create({
                component: ModalNewAppuntamento,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    console.log(detail.data);
                    //clienti.value = [detail.data, ...clienti.value];
                    openToast("Appuntamento inserito con successo", "toast_success");
                }
            });
            return modal.present();
        }

        /* Ad ogni accesso alla pagina carico gli eventi */
        /*     onIonViewWillEnter(() => {
      getEvents();
    }); */

        onMounted(() => {
            getEvents();
            //console.log(refCalendar);
        });

        return {
            loading,
            dateFormat,
            add,
            refresh,
            clipboard,
            checkField,
            //new field
            getEvents,
            events,
            date,
            selectedDay,
            refCalendar,
            setAttributes,
            dayClicked,
            getHours,
            parseHtmlEnteties,
            calendar,
            //eventi di oggi
            todayEvents,
            showTodayEvents,
            today,
            openDetailModal,
            riferimentoCliente,
            newAppuntamento,
            giornoCliccato,
            dataDaVisualizzare,
        };
    },
});
</script>

<style scoped>
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.base_text {
    font-size: 14px;
}
.pb-0 {
    padding-bottom: 0;
}

ion-item {
    font-size: 14px;
    --inner-padding-top: 5px;
    --inner-padding-bottom: 5px;
}
ion-label {
    margin: 0;
}

.data_richiesta {
    font-size: 12px;
}

.stato_richiesta {
    font-size: 11px;
}

/* New rules */
.no_events {
    color: #ef4444;
    font-size: 18px;
    font-weight: 500;
}

.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    min-height: 100%;
    background: #f8fafc;
}
.container_eventi {
    width: 100%;
    padding: 16px;
    /*padding-bottom: 64px;*/ /* to avoid overlap with latest richiesta */
}

/* New rules for calendar */
.selected_day {
    width: 100%;
    height: 100%;
    padding: 16px 0;
}
.selected_day .single_event {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.single_event .orari {
    font-size: 12px;
    /*margin-bottom: 4px;*/
}
.single_event .event_description {
    font-size: 12px;
    margin-top: 4px;
}
.single_event .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.selected_date {
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
    text-transform: capitalize;
}

.no_selected_event {
    color: #ef4444;
    font-size: 16px;
    font-weight: 500;
}

.single_event .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.show_more {
    display: inline;
    font-size: 10px;
    font-weight: 500;
    /*background-color: #f8fafc;*/
    color: #f8fafc;
    border: 1px solid #f8fafc;
    border-radius: 4px;
    padding: 2px 4px;
}
.description {
    margin-top: 8px;
    font-size: 12px;
}

.fade-enter-active,
.fade-leave-active {
    transition: max-height 0.15s ease-in-out;
}

.fade-enter-to,
.fade-leave-from {
    overflow: hidden;
    max-height: 150px;
}

.fade-enter-from,
.fade-leave-to {
    overflow: hidden;
    max-height: 0;
}

/* Nuovo evento */
.nuova_evento_container {
    width: 100%;
    padding-top: 16px;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.evento_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #16a34a;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.evento_button ion-icon {
    margin-right: 8px;
    font-size: 16px;
}
</style>
