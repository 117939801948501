<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Dettaglio</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="dark"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <div class="appuntamento">
          <div class="field">
            <div class="title">Data</div>
            <div class="value">
              {{ dateFormat(appuntamento.events_date_start) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Ora inizio</div>
            <div class="value">
              {{ hourFormat(appuntamento.events_date_start) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Ora fine</div>
            <div class="value">
              {{ hourFormat(appuntamento.events_date_end) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Cliente</div>
            <div class="value customer_link" @click="openDetailModal(appuntamento)">
              {{ riferimentoCliente(appuntamento) }}
            </div>
          </div>
          <div v-if="appuntamento.customers_city" class="field_note">
            <div class="title">Indirizzo cliente</div>
            <div class="value">
              <a
                :href="
                  `https://maps.google.com/?q=${appuntamento.customers_city ? appuntamento.customers_city : ''} ${
                    appuntamento.customers_address ? appuntamento.customers_address : ''
                  } ${appuntamento.customers_zip_code ? appuntamento.customers_zip_code : ''}`
                "
                target="_blank"
                class="mobile_link"
              >
                <ion-icon :icon="navigateOutline"></ion-icon>
                {{ appuntamento.customers_city }},
                {{ appuntamento.customers_address }}
              </a>
            </div>
          </div>
          <div class="field_note">
            <div class="title">Descrizione</div>
            <div class="value">
              {{ appuntamento.events_description ? parseHtmlEnteties(appuntamento.events_description) : "-" }}
            </div>
          </div>
          <div class="delete_appointment">
            <div class="btn_delete" @click="deleteAppointment()">Elimina appuntamento</div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  //IonBackButton,
  IonTitle,
  IonContent,
  IonIcon,
  IonButton,
  modalController,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

import { arrowBackOutline, navigateOutline } from "ionicons/icons";

import { openToast } from "../services/toast";

import apiEventi from "../services/appuntamenti";

import ClienteDetail from "./ClienteDetail.vue";

import moment from "moment";

export default defineComponent({
  name: "AppuntamentoDetail",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
  },
  setup(props, _context) {
    const route = useRoute();
    const id = route.params.id; //appuntamento_id by route params
    const loading = ref(false);
    const appuntamento = ref([]);
    const successDeletion = ref(false);
    //console.log(props.data);
    appuntamento.value = { ...props.data };
    //console.log(appuntamento.value);

    /**
     * ! Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     * ! Close modal on deletion, if deleted pass back parameter and filter todayEvents and events array
     */
    const closeModalOnSubmit = async (deletion, id) => {
      if (deletion && id) {
        //console.log("deletion success: ", deletion, "  --- event_id: ", id);
        await modalController.dismiss(id);
      } else {
        //console.log("successResponse ", successResponse.value);
        await modalController.dismiss();
      }
    };

    /**
     * ! Delete appointemnt
     */
    const deleteAppointment = async () => {
      try {
        const event_id = appuntamento.value.events_id;
        const res = await apiEventi.deleteAppointment(appuntamento.value.events_id);
        if (res.data.status === 0) {
          openToast("Appuntamento cancellato", "toast_success");
          successDeletion.value = true;
          closeModalOnSubmit(successDeletion.value, event_id);
        }
      } catch (error) {
        console.error(error);
        successDeletion.value = false;
        openToast("Errore durante la cancellazione dell'appuntamento", "toast_danger");
      }
    };

    /**
     * ! Show correct user info
     */
    const riferimentoCliente = (cliente) => {
      if (cliente.customers_company) {
        return cliente.customers_company;
      } else if (cliente.customers_name && cliente.customers_last_name) {
        return `${cliente.customers_name} ${cliente.customers_last_name}`;
      }
      return "-";
    };

    //Return '-' if a appuntamento field is null or empty
    function checkField(field) {
      if (!field || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }
    /**
     * Format date from YYYY/MM/DD HH:mm:ss to HH:mm
     */
    function hourFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("HH:mm");
      } else {
        return "-";
      }
    }

    /**
     * Remove html tag from events description
     */
    function parseHtmlEnteties(str) {
      if (str) {
        const strippedString = str.replace(/(<([^>]+)>)/gi, "");
        return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
          const num = parseInt(numStr, 10); // read num as normal number
          return String.fromCharCode(num);
        });
      }
    }

    /**
     * Passing customer object ClienteDetail page
     */
    async function openDetailModal(evento) {
      const modal = await modalController.create({
        component: ClienteDetail,
        componentProps: {
          data: evento,
        },
      });
      return modal.present();
    }

    return {
      id,
      loading,
      checkField,
      dateFormat,
      appuntamento,
      arrowBackOutline,
      closeModal,
      hourFormat,
      navigateOutline,
      riferimentoCliente,
      parseHtmlEnteties,
      deleteAppointment,
      openDetailModal,
    };
  },
});
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative; /* to be used for new appuntamento btn */
  padding: 16px;
  min-height: 100%;
  background: #f8fafc;
}

.appuntamento {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.appuntamento .field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e8f0;
}
.appuntamento .field_note {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e8f0;
}
.field .title,
.field_note .title {
  font-size: 14px;
  font-weight: 500;
  color: #475569;
}
.field_note .title {
  margin-bottom: 8px;
}
.field .value,
.field_note .value {
  font-size: 14px;
}

.mobile_link {
  text-decoration: none;
  color: #1279ac;
}

.field_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.appuntamento .delete_appointment {
  width: 100%;
  display: flex;
  padding: 12px 6px;
  margin-bottom: 10px;
}
.btn_delete {
  width: 100%;
  padding: 6px;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  background-color: #ef4444;
  font-weight: bold;
  font-size: 12px;
}

.customer_link {
  color: #1279ac;
}
</style>
